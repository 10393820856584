import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bdc891fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal"
}
const _hoisted_2 = { class: "modal_dialog" }
const _hoisted_3 = { class: "modal_header" }
const _hoisted_4 = { class: "modal_body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_unicon = _resolveComponent("unicon")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "modal_backdrop",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
            }),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
                _createElementVNode("button", {
                  type: "button",
                  class: "modal_close no-background",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal()))
                }, [
                  _createVNode(_component_unicon, {
                    name: "times",
                    fill: "white",
                    width: "50",
                    height: "50"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "body", {}, undefined, true)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}