import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-756f53f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_unicon = _resolveComponent("unicon")!
  const _component_ToolTip = _resolveComponent("ToolTip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "form",
      enctype: "multipart/form-data",
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.uploadImage && _ctx.uploadImage(...args)), ["prevent"]))
    }, [
      _withDirectives(_createElementVNode("input", {
        class: "inputbar",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.link) = $event)),
        placeholder: "Enter link to image here..."
      }, null, 512), [
        [_vModelText, _ctx.link]
      ])
    ], 32),
    _createVNode(_component_ToolTip, { text: "Paste" }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.paste && _ctx.paste(...args))),
          class: "no-background icon"
        }, [
          _createVNode(_component_unicon, {
            name: "clipboard",
            fill: "var(--accent-1)",
            "hover-fill": "white"
          })
        ])
      ]),
      _: 1
    })
  ]))
}