import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb40c280"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "frame" }
const _hoisted_2 = {
  ref: "chart",
  class: "chart"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = ["onMouseenter"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colorNames, (item) => {
        return (_openBlock(), _createElementBlock("a", {
          key: item,
          href: 'https://www.color-hex.com/color/' + item.hex,
          target: "_blank"
        }, [
          _createElementVNode("div", {
            onMouseenter: () => _ctx.onHover(item.pos, item.hex),
            class: "color",
            style: _normalizeStyle({ 'background-color': `#${item.hex}` })
          }, _toDisplayString(`${item.name}`), 45, _hoisted_4)
        ], 8, _hoisted_3))
      }), 128))
    ], 512)
  ]))
}