import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_unicon = _resolveComponent("unicon")!

  return (_openBlock(), _createElementBlock("button", {
    style: _normalizeStyle(_ctx.styling),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args))),
    class: "button"
  }, [
    _createVNode(_component_unicon, {
      name: _ctx.icon,
      fill: "white",
      width: "1.2em",
      height: "1.2em"
    }, null, 8, ["name"]),
    _createTextVNode(_toDisplayString(_ctx.text), 1)
  ], 4))
}