import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ebc7f62"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  ref: "pixelhover",
  class: "pixel-hover"
}
const _hoisted_2 = { class: "frame" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "resolution"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.toolTip)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, null, 512))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        ref: "img",
        style: _normalizeStyle(_ctx.grayScale ? { filter: 'grayscale(1)' } : {}),
        class: "pic",
        src: _ctx.data,
        target: "_blank"
      }, null, 12, _hoisted_3),
      (!_ctx.grayScale)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.width) + " x " + _toDisplayString(_ctx.height) + " px ", 1))
        : _createCommentVNode("", true)
    ])
  ], 64))
}