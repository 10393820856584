import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-569d82a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upload-container" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  key: 0,
  class: "upload-box"
}
const _hoisted_4 = {
  key: 1,
  class: "link-bar"
}
const _hoisted_5 = {
  key: 2,
  class: "loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadBox = _resolveComponent("UploadBox")!
  const _component_LinkBar = _resolveComponent("LinkBar")!
  const _component_Processing = _resolveComponent("Processing")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", {
      ref: "pasteboard",
      class: "paste",
      onPaste: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getImageFromClipboard($event)))
    }, null, 544),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.uploading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_UploadBox, { onSubmitImage: _ctx.uploadFile }, null, 8, ["onSubmitImage"])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.uploading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_LinkBar, { onSubmitImage: _ctx.uploadFile }, null, 8, ["onSubmitImage"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.uploading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_Processing)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}